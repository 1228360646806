import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, BoxProps, styled, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/images/brightloop-logo-web.svg";

const DealSidebarBox = styled(Box)<BoxProps>(({ theme }) => ({
    width: "210px",
    position: "fixed",
    top: 0,
    left: 0,
    overflow: "hidden",
    backgroundColor: "#e1e6eb",
    color: "#000000",
    height: "100%",
    padding: "110px 0 20px",
    zIndex: "999",
    transition: "width 0.5s",
    flexGrow: 1,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    "& p, a": {
        display: "block",
        padding: "10px 20px 10px 40px",
        fontSize: "1rem",
        margin: 0,
        textDecoration: "none",
        color: "#000000",
        "&:hover": {
            backgroundColor: "#d3d8dd",
        },
        "&.disabled": {
            color: "#667788",
            cursor: "not-allowed",
            "&:hover": {
                backgroundColor: "#e1e6eb",
            },
        },
        "&.active": {
            backgroundColor: "#667788",
            color: "#ffffff",
            "& span": {
                color: "#ffffff",
            }
        },
        "& .link-stats": {
            marginLeft: "5px",
            color: "#667788",
        }
    },
    "& .tab-section-header": {
        padding: "10px 20px 10px 40px",
        marginTop: "20px",
        color: "#667788",
        fontWeight: 500,
        display: "flex",
        flexDirection: "row",
        "&::after": {
            borderBottom: "1px solid #667788",
            content: '""',
            flex: "1 1",
            margin: "auto",
            marginLeft: "10px",
        },
    },
    [theme.breakpoints.down('md')]: {
        width: "60px",
        "& .link-label": {
            display: "none"
        },
        "& .tab-section-header": {
            padding: "10px 0",
            margin: "0 15px",
            "& .header-label": {
                display: "none"
            },
            "&::after": {
                margin: 0,
            }
        },
        "& p, a": {
            padding: "10px 0",
            textAlign: "center",
            "& span": {
                display: "none"
            }
        }
    },
    "& .bottom-row": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        margin: "10px auto",
        //width: "100%",
        //marginTop: "1rem",
        //zIndex: 100,
        //position: "relative",
        "& button": {
            padding: "0.5rem 1.5rem",
            borderRadius: "0.375rem",
            backgroundColor: "#1a5dbd",
            border: "2px solid #1a5dbd",
            color: "#ffffff",
            "&:hover": {
                backgroundColor: "#4d8ae0",
                color: "#ffffff",
            },
        },
        "& p": {
            padding: "0.5rem 0",
            margin: 0,
            fontSize: "0.8rem",
            color: "#000000",
        },
        "& img.logo-image": {
            maxHeight: "34px",
            zIndex: 1, // Ensure it is above the background image
            "&.brightloop": {
                maxHeight: "24px",
            },
        },
    },
}));


const SectionLink = ({ to, icon, text, stats, enabled }: any) => {

    if (!enabled) {
        return (
            <p className="disabled">
                <FontAwesomeIcon icon={icon} /><span className="link-label">&nbsp;&nbsp;{text}</span>
            </p>
        );
    }

    return (
        <NavLink to={to} end>
            <FontAwesomeIcon icon={icon} /><span className="link-label">&nbsp;&nbsp;{text} {stats && <span className="link-stats">({stats})</span>}</span>
        </NavLink>
    );
}

export const DealSidebar = ({ project, role }: any) => {

    const disabled = (project.viewpoints.length === 0);
    const hasViewpoints = project.viewpoints.length > 0;
    const isDraft = project.status === "draft";
    const isGuest = role === "guest";
    const shareLink = project.project_share_links[0];
    const hasAnalysis = project.iterations?.at(-1)?.analysis_url && project.iterations?.at(-1)?.analysis_url !== "";

    const numOffersToAdvise = project?.viewpoints?.filter((viewpoint: any) => viewpoint?.interest_advisor)?.length ?? 0;
    const numCandidates = project?.viewpoints?.filter((viewpoint: any) => viewpoint?.interest_work)?.length ?? 0;
    const totalProspectiveSales = project?.viewpoints?.filter((viewpoint: any) => viewpoint?.interest_demo || viewpoint?.interest_beta)?.reduce((acc: number, viewpoint: any) => acc + viewpoint?.max_spend, 0) ?? 0;

    const totalPotentialInvestment = project?.viewpoints?.filter((viewpoint: any) => (viewpoint?.interest_investor || viewpoint.survey_response?.funded || viewpoint.survey_response?.reserved_spot))?.reduce((acc: number, viewpoint: any) => {
        if (viewpoint?.survey_response?.funded) return acc + viewpoint?.survey_response?.funded_amount;
        if (viewpoint?.survey_response?.reserved_spot) return acc + viewpoint?.survey_response?.reserved_amount;
        return acc + (viewpoint?.investment_amount_high + viewpoint?.investment_amount_low) / 2;
    }, 0) ?? 0;

    const baseUrl = role === "guest" ? `/guest/${shareLink.share_url}` : `/o/${project.organization.tag}/d/${project.tag}`;

    const tabStatus = {
        executives: isDraft ? false : isGuest ? shareLink.display_executives : true,
        viewpoints: isGuest ? shareLink.display_viewpoints : hasViewpoints,
        analysis: isGuest ? shareLink.display_analysis && hasAnalysis : hasAnalysis,
        prospects: isGuest ? shareLink.display_prospects : hasViewpoints,
        industry_angels: isGuest ? shareLink.display_investors : hasViewpoints,
        advisors: false,
        candidates: false,
        setup: !isGuest,
        collaboration: !isGuest && !isDraft,
        availability: !isGuest && !isDraft
    };

    return (
        <DealSidebarBox>
            <div className="deal-tabs">
                <SectionLink to={`${baseUrl}`} icon="home" text="Executives" enabled={tabStatus.executives} />

                <div className="tab-section-header"><span className="header-label">Insights</span></div>
                <SectionLink to={`${baseUrl}/viewpoints`} icon="users" text="Viewpoints" stats={project?.viewpoints.length} enabled={tabStatus.viewpoints} />
                <SectionLink to={`${baseUrl}/analysis`} icon="lightbulb" text="Analysis" enabled={tabStatus.analysis} />

                <div className="tab-section-header"><span className="header-label">Opportunities</span></div>
                <SectionLink to={`${baseUrl}/prospects`} icon="chart-line" text="Prospects" stats={`\$${Intl.NumberFormat('en', { notation: 'compact' }).format(totalProspectiveSales)}`} enabled={tabStatus.prospects} />
                <SectionLink to={`${baseUrl}/industry-angels`} icon="hand-holding-dollar" text="Angels" stats={`\$${Intl.NumberFormat('en', { notation: 'compact' }).format(totalPotentialInvestment)}`} enabled={tabStatus.industry_angels} />
                <SectionLink to={`${baseUrl}/advisors`} icon="handshake-angle" text="Advisors" stats={numOffersToAdvise} enabled={tabStatus.advisors} />
                <SectionLink to={`${baseUrl}/candidates`} icon="building-user" text="Candidates" stats={numCandidates} enabled={tabStatus.candidates} />

                {role !== "guest" &&
                    <>
                        <div className="tab-section-header"><span className="header-label">Management</span></div>
                        <SectionLink to={`${baseUrl}/setup`} icon="gear" text="Deal Setup" enabled={tabStatus.setup} />
                        <SectionLink to={`${baseUrl}/collaboration`} icon="user-plus" text="Team & Guests" enabled={tabStatus.collaboration} />
                        { project.prospecting_enabled && <SectionLink to={`${baseUrl}/availability`} icon="calendar" text="Calendar Setup" enabled={tabStatus.availability} /> }
                    </>
                }
            </div>
            {role === "guest" &&
                <Box className="bottom-row">
                    <Box>
                        <Typography variant="body2">Powered by</Typography>
                        <img className="logo-image brightloop" src={logo.toString()} />
                    </Box>
                </Box>
            }
        </DealSidebarBox>

    );
}