import { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Select from 'react-select';
import { getOrganizations, getUser, updateUser } from '../services/gutcheck-admin-api.service';
import Membership from '../../users/types/Membership';

type TAdminUserEdit = {
    email: string,
    name: string,
    role: any,
    organizations: any | null
};

const initState: TAdminUserEdit = {
    email: '',
    name: '',
    role: '',
    organizations: null
};

export const AdminUserEdit = () => {
    const [organizations, setOrganizations] = useState();
    const [form, setForm] = useState(initState);

    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const { userId } = useParams();

    useEffect(() => {

        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();

            const [orgData, userData] = await Promise.all([
                getOrganizations(accessToken),
                getUser(userId ?? "-1", accessToken)
            ]);

            if (userData) {
                setForm({
                    email: userData.data.user.email_address,
                    name: userData.data.user.name,
                    role: { value: userData.data.user.role, label: userData.data.user.role },
                    organizations: userData.data.user.memberships.filter((membership: Membership) => membership.organization).map((membership: Membership) => { return  {...membership.organization, value: membership.organization.id, label: membership.organization.name } })
                });
            }

            if (orgData) {
                setOrganizations(orgData.data.organizations.map((organization: any) => {
                    return { ...organization, value: organization.id, label: organization.name };
                }));
            }

        };

        fetchData();

        return;
    }, []);


    const handleChange = (event: any) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };

    const handleRoleChange = (event: any) => {
        setForm({
            ...form,
            role: event
        });
    };

    const handleOrganizationsChange = (event: any) => {
        setForm({
            ...form,
            organizations: event
        });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();
        const user = {
            email_address: form.email,
            name: form.name,
            role: form.role.value,
            organizations: form.organizations.map( (organization: any) => { return organization.id})
        }
        if (userId) await updateUser(userId, user, accessToken);
        return navigate('/admin/users');
    };

    const userRoles = [
        { value: "user", label: "user" },
        { value: "admin", label: "admin" }
    ];

    return (
        <Fragment>
            <h3>Edit User: {form.email}</h3>
            <form onSubmit={handleSubmit} className="edit-page-form">
                <div className="edit-page-form-field textfield-wrapper">
                    <label htmlFor="email">Email</label>
                    <input
                        id="email"
                        type="text"
                        value={form.email || ''}
                        onChange={handleChange}
                    />
                </div>
                <div className="edit-page-form-field textfield-wrapper">
                    <label htmlFor="name">Name</label>
                    <input
                        id="name"
                        type="text"
                        value={form.name || ''}
                        onChange={handleChange}
                    />
                </div>                <div className="edit-page-form-field role-wrapper">
                    <label htmlFor="roles">Role</label>
                    <Select
                        className="edit-page-form-select"
                        inputId="role"
                        name="role"
                        options={userRoles}
                        value={form.role}
                        onChange={handleRoleChange}
                    />
                </div>
                { false &&
                <div className="edit-page-form-field organizations-wrapper">
                    <label htmlFor="organizations">Organizations</label>
                    <Select
                        isMulti
                        className="edit-page-form-select"
                        classNamePrefix="select"
                        name="organizations"
                        options={organizations}
                        value={form.organizations}
                        onChange={handleOrganizationsChange}
                    />
                </div>
}
                <button type="submit">Submit</button>
            </form>
        </Fragment>
    );
}
