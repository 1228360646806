import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, RouterProvider, createBrowserRouter } from "react-router-dom";
import { Root } from "./App";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import "./index.css";
import { pdfjs } from "react-pdf";

const container: HTMLElement = document.getElementById("root")!;
const root = createRoot(container);

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const router = createBrowserRouter([
  {
    path: "*",
    element: (
      <Auth0ProviderWithNavigate>
        <Root />
      </Auth0ProviderWithNavigate>
    ),
  },
]);

export default function App() {
  return <RouterProvider router={router} />
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

